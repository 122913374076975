<template>
    <div class="people-search">
        <people-list :list-endpoint="listEndpoint" />
    </div>
</template>

<script>
export default {
    name: "PeopleSearch",
    components: {
        PeopleList: () => import(/* webpackChunkName: "people-list" */ "@/components/organisms/people-list.vue")
    },
    props: {
        listEndpoint: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.people-search {

}
</style>
